//const baseUrl = process.env.NODE_ENV === "development" ? 'http://api.stage.truabilities.com/truabilities-dev/api/v1/' : 'https://api.truabilities.com/truabilities/api/v1/';
// const baseUrl = 'http://localhost:4546/truabilities-dev/api/v1/';


const baseUrl = 'https://api.truabilities.com/truabilities/api/v1/';
// const baseUrl = 'https://api3.truabilities.com/truabilities/api/v1/'; // Prod API
// const baseUrl = 'https://apiv3.stage.truabilities.com/truabilities-dev/api/v1/'; // Dev API 


/**
 * ADMIN BASE URL
 */
const superAdminBaseUrl = baseUrl + "admin/";

/**
 * USER PORTAL URL
 */
const userPortalBaseUrl = baseUrl + "user-portal/";

/**
 * ADMIN LOGIN
 */
/** Super Admin Login **/
export const superAdminLoginAuth = superAdminBaseUrl + 'auth/login';

/**
 * ADMIN MENU AND PRIVILEGES
 */
/** Super Admin Menu with DB */
export const menuSync = superAdminBaseUrl + 'menu/sync';
/** Super Admin All Menu Fetch */
export const allMenu =  superAdminBaseUrl + 'menu';
/** Super Admin Menu Wise Privilege Fetch */
export const getPrivilegebyMenuUUID =  superAdminBaseUrl + 'privilege';
/** Super Admin Menu action set */
export const allMenuPrivilege =  superAdminBaseUrl + 'menu/actions';


/**
 * ADMIN ROLES
 */
/** Super Admin Role */
export const allRole =  superAdminBaseUrl + 'role';


/**
 * ADMIN USERS
 */
/** Super Admin User List **/
export const allUser = superAdminBaseUrl + 'user';
/** Super Admin specific user view **/
export const userById = superAdminBaseUrl + 'user/one';
/** Super Admin user export **/
export const userExport = superAdminBaseUrl + 'user/export';


/**
 * ADMIN USERS
 */
/** Super Admin dashboard details **/
export const getDashBoardData = superAdminBaseUrl + 'dashboard';

/** Super Admin dashboard details **/
export const getDashBoardInitData = superAdminBaseUrl + 'dashboard/website-init-stats';

/** Super Admin dashboard details **/
export const getDashBoardInitDataDropdown = superAdminBaseUrl + 'dashboard/init-stats-dropdown';

/**
 * ADMIN CUSTOMERS
 */
/** Super Admin Customer List **/
export const allCustomer = superAdminBaseUrl + 'customer';

/** Super Admin specific Customer view **/
export const customerById = superAdminBaseUrl + 'customer/one';

/** Super Admin Customer export **/
export const customerExport = superAdminBaseUrl + 'customer/export';


/**
 * ADMIN LOCATION FETCH
 */
/** Super Admin Location **/
export const allLocation = superAdminBaseUrl + 'utility/countries';

/**
 * ADMIN Search
 */
/** Super Admin Invoice Search **/
export const searchByAll = superAdminBaseUrl + 'invoice/search';

/**
 * ADMIN WEBSITE
 */
/** Super Admin Website List **/
export const allWebsite = superAdminBaseUrl + 'website';

/** Super Admin Website List **/
export const freeWebsites = superAdminBaseUrl + 'website/free-websites';

/** Super Admin specific Website view **/
export const websiteById = superAdminBaseUrl + 'website/one';

/* Super Admin coupon validate */
export const validateCoupon = superAdminBaseUrl + 'validate-coupone';

/* User portal website script installation guide */
export const websiteInstallationGuide = superAdminBaseUrl + 'website/install-request';

/* User portal Website Service */
export const websiteService = superAdminBaseUrl + 'website/service';

/** Super Admin Active Website view **/
export const activeWebsites = superAdminBaseUrl + 'website/active-sites';

/** Super Admin Active Website view **/
export const deactiveWebsites = superAdminBaseUrl + 'website/deactive-sites';

/** Super Admin Search Website view **/
export const searchWebsite = superAdminBaseUrl + 'website/search';

/** Super Admin Website export **/
export const websiteExport = superAdminBaseUrl + 'website/export';

/** Super Admin Website export **/
export const activeWebsiteExport = superAdminBaseUrl + 'website/export/active';

/** Super Admin Website export **/
export const deactiveWebsiteExport = superAdminBaseUrl + 'website/deactive/export';

/** Super Admin Website export **/
export const websiteHits = superAdminBaseUrl + 'websitehit/';

/** Super Admin Website capping update **/
export const websiteCapping = superAdminBaseUrl + 'website/capping';


/**
 * ADMIN Website Scan
 */
/** Super Admin Scan List **/
export const allScanDetails = superAdminBaseUrl + 'website-scan';

/** Super Admin Scan Add **/
export const addScanDetails = superAdminBaseUrl + 'website-scan';

/** Super Admin Scan Add **/
export const deleteScanDetails = superAdminBaseUrl + 'website-scan';

/** Super Admin Re Scan **/
export const reScanDetails = superAdminBaseUrl + 'website-scan/re-scan';

/** Super Admin download Scan **/
export const downloadScanDetails = superAdminBaseUrl + 'website-scan/download';

/** Super Admin edit Scan **/
export const editScanDetails = superAdminBaseUrl + 'website-scan';

/** Super Admin All Scan **/
export const getAllScanSetting = superAdminBaseUrl + 'scan-setting';

/** Super Admin get Scan by ID**/
export const getAllScanSettingById = superAdminBaseUrl + 'scan-setting/one';

/** Super Admin Update Scan**/
export const updateAllScanSetting = superAdminBaseUrl + 'scan-setting/update';

/** Super Admin get Scan by ID**/
export const updateAutoScanSetting = superAdminBaseUrl + 'scan-setting/auto-scan';

/** Super Admin get Scan by ID**/
export const deleteScanSettingDetails = superAdminBaseUrl + 'scan-setting';

/**
 * ADMIN PACKAGE
 */
/** Super Admin Package List **/
export const allPackage = superAdminBaseUrl + 'package';

/** Super Admin specific Package view **/
export const packageById = superAdminBaseUrl + 'package/one';

/** Super Admin specific Package Hit list **/
export const packageHitList = superAdminBaseUrl + 'package/hits-list';


/**
 * ADMIN Feature
 */
/** Super Admin Feature List **/
export const packageFeatures = superAdminBaseUrl + 'package/feature-list';


/**
 * ADMIN PACKAGE TYPE
 */
/** Super Admin Package Type **/
export const packageType = superAdminBaseUrl + 'package/type';


/**
 * ADMIN INVOICE
 */
/** Super Admin Invoice List **/
export const allInvoice = superAdminBaseUrl + 'invoice';

/** Super Admin specific Invoice view **/
export const invoiceById = superAdminBaseUrl + 'invoice/one';

/** Super Admin specific Invoice view **/
export const freeWebsiteInvoices = superAdminBaseUrl + 'invoice/free-invoices';

/** Super Admin specific Invoice view **/
export const invoiceDownload = superAdminBaseUrl + 'invoice/download-invoice/';

/** Super Admin specific Invoice view **/
export const invoiceListExport = superAdminBaseUrl + 'invoice/export/';

/** Super Admin Send Invoice Receipt **/
export const sendInvoiceReceipt = superAdminBaseUrl + 'invoice/send-invoice/';


/**
 * ADMIN LANGUAGE
 */
/** Super Admin Languages **/
export const allLanguageList = superAdminBaseUrl + 'language';

/** Super Admin Language Flags **/
export const allLanguageFlags = allLanguageList + '/country/flag/';

/** Super Admin Language Voice **/
export const allLanguageVoice = allLanguageList + '/voice/support/';

/** Super Admin Language Code **/
export const allLanguageCode = allLanguageList + '/country/name-code/';

/** Super Admin Language Export **/
export const languageExport = allLanguageList + '/export/';

/** Super Admin Language by Id **/
export const languageById = allLanguageList + '/one/';


/**
 * ADMIN LANGUAGE KEY
 */
/** Super Admin Languages **/
export const allLanguageKeyList = allLanguageList + '/key/';

/** Super Admin Languages **/
export const languageKeyById = allLanguageKeyList + '/one/';


/**
 * ADMIN LANGUAGE TEXT
 */
/** Super Admin Languages **/
export const allLanguageTextList = allLanguageList + '/text/';


/**
 * ADMIN DEVICE INFO
 */
/** Super Admin Device info **/
export const allDeviceList = superAdminBaseUrl + 'device-info/';

/**
 * ADMIN DEVICE INFO
 */
/** Super Admin Device info by ID **/
export const deviceInfoById = superAdminBaseUrl + 'device-info/one';

/** Super Admin Device info Export **/
export const allDeviceListExport = allDeviceList + 'export';

/** Super Admin Device info Export **/
export const allPageList = superAdminBaseUrl + 'pages';

export const pageById = superAdminBaseUrl + 'pages/one';

export const allPageTypeList = superAdminBaseUrl + 'pages/list';


/**
 * ADMIN LEADS
 */
/** Super Admin Device info **/
export const allLeadsList = superAdminBaseUrl + 'form-lead';

/** Super Admin Device info Export **/
export const allLeadsListExport = superAdminBaseUrl + 'form-lead/export';

export const deleteFormLead = superAdminBaseUrl + 'form-lead/delete-lead';


/**
 * ADMIN MEDIA
 */
/** Super Admin Media List **/
export const allMediaItems = superAdminBaseUrl + 'media';

/** Super Admin Media Folder Create **/
export const mediaFolderCreate = allMediaItems + '/create';


/**
 * ADMIN SETTINGS
 */
/** Super Admin Settings **/
export const adminSetting = superAdminBaseUrl + 'settings/general/';

/** Super Admin Email Type **/
export const adminEmailType = superAdminBaseUrl + 'email/type/';

/** Super Admin Email Template **/
export const adminEmailTemplates = superAdminBaseUrl + 'email/templates/';

/** Super Admin Email Recipient **/
export const adminEmailRecipients = superAdminBaseUrl + 'email/receipents/';

/** Super Admin CouchDB **/
export const adminCouchDB = superAdminBaseUrl + 'settings/couchdb';

/** Super Admin CouchDB delete**/
export const deleteAdminCouchDB = superAdminBaseUrl + 'settings/couchdb';


/**
 * ADMIN COUPON
 */
/** Super Admin Coupon **/
export const allCouponList = superAdminBaseUrl + 'coupon/';

/** Super Admin Coupon Export **/
export const allCouponExport = allCouponList + 'export/';


/**
 * ADMIN COOKIE
 */
/** Super Admin Cookie **/
export const allCookieList = superAdminBaseUrl + 'cookie/';

/** Super Admin Cookie Export **/
export const cookieExport = superAdminBaseUrl + 'cookie/export/';

/** Super Admin Cookie Import **/
export const cookieImport = superAdminBaseUrl + 'cookie/import/';

/** Super Admin Cookie Category **/
export const allCookieCategory = superAdminBaseUrl + 'cookie/category/';

/** Super Admin Cookie Category By ID **/
export const CookieCategoryByID = superAdminBaseUrl + 'cookie/category/one';


/**
 * ADMIN SETTINGS
 */
/** Super Admin Settings **/
//export const adminSetting = superAdminBaseUrl + 'settings/general/';

/** Super Admin Email Type **/
//export const adminEmailType = superAdminBaseUrl + 'email/type/';

/** Super Admin Email Template **/
export const adminPdfTemplates = superAdminBaseUrl + 'pdflayout';

export const redisFlush = superAdminBaseUrl + 'settings/flush-redis';

/** Super Admin Email Recipient **/
//export const adminEmailRecipients = superAdminBaseUrl + 'email/receipents/';

/** Super Admin CouchDB **/
//export const adminCouchDB = superAdminBaseUrl + 'settings/couchdb';



/**
 *  User Portal Login 
 **/
export const userPortalLoginAuth = userPortalBaseUrl + 'auth/login';

/* WP user login */
export const userPortalWPLoginAuth = userPortalBaseUrl + 'auth/wp-login';

/* user verify */
export const userPortalUserVerify = userPortalBaseUrl + 'user/verify';

/* User portal Forget Password */
export const userPortalForgetPass = userPortalBaseUrl + 'auth/login';

/* User portal User Profile */
export const userPortalUser = userPortalBaseUrl + 'user';

/* User portal User Profile */
export const userPortalChangePassword = userPortalBaseUrl + 'user/change-password';

/* User portal User Profile */
export const userPortalUserProfile = userPortalBaseUrl + 'user/profile';

/* User portal package type */
export const userPortalPackageType = userPortalBaseUrl + 'package/types';

/* User portal website list */
export const userPortalWebsite = userPortalBaseUrl + 'website';

export const userPortalWebsiteScanLogs = userPortalBaseUrl + 'website/scan-logs';

export const userPortalReGenerateLighthouse = userPortalBaseUrl + 'website/re-generate-lighthouse';


/* User portal website script installation guide */
export const userPortalWebsiteInstallationGuide = userPortalBaseUrl + 'website/install-request';

/* User portal website by Id */
export const userPortalWebsiteOne = userPortalBaseUrl + 'website/one';

/* User portal download script */
export const userPortalWebsiteScript = userPortalBaseUrl + 'website/download-script';

/* User portal coupon validate */
export const userPortalValidateCoupon = userPortalBaseUrl + 'validate-coupone';

/* User portal Website Service */
export const userPortalWebsiteService = userPortalBaseUrl + 'website/service';

/* User portal card brand fetch */
export const userPortalCardList = userPortalBaseUrl + 'card';

/* User portal card brand fetch */
export const userPortalCardBrand = userPortalBaseUrl + 'card/brand';

/* User portal card update */
export const userPortalCardUpdate = userPortalBaseUrl + 'card/update-card';

/* User portal Branding List */
export const userPortalBranding = userPortalBaseUrl + 'branding';

export const userPortalSearchBranding = userPortalBaseUrl + 'branding/search';

/* User portal Branding List */
export const userPortalBrandingWebsiteList = userPortalBaseUrl + 'branding/website-list';

/* User portal Branding by id */
export const userPortalBrandingById = userPortalBaseUrl + 'branding/one';

/* User portal Branding Icons */
export const userPortalBrandingIcons = userPortalBaseUrl + 'branding/icons';

/* User portal Invoice List */
export const userPortalInvoice = userPortalBaseUrl + 'invoice';

/* User portal Invoice Download */
export const userPortalInvoiceDownload = userPortalBaseUrl + 'invoice/download-invoice';

/* User portal Billings */
export const userPortalBilling = userPortalBaseUrl + 'billing';

/* User portal Branding T&C */
export const userPortalBillingTerms = userPortalBaseUrl + 'billing/terms-and-conditions';

/* User portal Website scan list */
export const userPortalWebsiteScan = userPortalBaseUrl + 'website-scan/';

/* User portal Website re-scan */
export const userPortalWebsiteRescan = userPortalWebsiteScan + 're-scan';

/* User portal scaned website by ID */
export const userPortalScanedById = userPortalBaseUrl + 'website-scan/one';

/* User portal scaned website by ID */
export const userPortalDownloadReport = userPortalBaseUrl + 'website-scan/download';

/* User portal scaned violation */
export const userPortalScanedviolation = userPortalBaseUrl + 'website-scan/violation';

/* User portal Ntification */
export const userPortalNotification = userPortalBaseUrl + 'notification';

/* User portal User Settings */
export const userPortalUserSettings = userPortalBaseUrl + 'user-settings';

/* User portal User Settings edit */
export const userPortalSettingsUpdate = userPortalBaseUrl + 'user-settings';

/* User portal Monitoring */
export const websiteMonitoring = userPortalBaseUrl + 'monitoring';

export const websiteMonitoringStatus = userPortalBaseUrl + 'monitoring/status';

export const websiteReMonitoringReport = userPortalBaseUrl + 'monitoring/re-check';

export const allPrivacyLeads = userPortalBaseUrl + 'privacy-form-lead';

export const UpPrivacyLeadById = userPortalBaseUrl + 'privacy-form-lead/one';

export const approveRequests = userPortalBaseUrl + 'privacy-form-lead/approve-request';

export const searchDeviceInfo = superAdminBaseUrl + 'device-info/search';



/* User Terms content */
export const termsContent = baseUrl + 'page/content';
